.services{
    width: 100vw;
    height:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 30px 0px;
    padding-top: 50px;
}

.services_heading{
    /* font-family: 'Poppins'; */
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    padding: 25px;
    font-size: 40px;
}

.card_container{
    width: 90%;
    height:fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 25px 0px 25px 0px;
    margin: 0 25px 0 25px;
}

.card_row{
    display: flex;
    justify-content: space-evenly;
    
}

.card{
    height: fit-content;
    width: fit-content;
    border-radius: 50%; 
    overflow: hidden;
    margin: 25px 0 25px 0;
}
.service_card_front{ 
    height: 300px;
    display: flex;
    flex-direction: column ;
}

.card_caption{
    line-height: 50px;
    height:50px;
    width:300px;
    text-align: center;
    color: black;
    text-transform: capitalize;
    font-size: x-large;
    /* font-family: "Poppins"; */
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    background-color: transparent;
}


.service_card_back{
    display: none;
}

.back_head{
    height:300px;
    width:300px;
    color: black;
    text-transform: capitalize;
    font-size: x-large;
    font-family: "Poppins";
    align-self: baseline;
    background-color: grey;
}

@media (max-width:1050px) {
    
    .card_row{
        transition-property: all;
        transition-delay: 2s;
        flex-direction: column;
        align-items: center;
    }

    
}