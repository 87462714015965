.home{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.photoView{
    height: 100%;
    width: 100%;
    background:fixed;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:start;
    padding-left: 60px;

}
.photoView.one{
    background-image : url("../images/home_img1.jpg");

    .home_heading{

        display: block;
        color: black;
    };
    .home_slogan{
        display: block;
    }
    
}
.photoView.two{
    align-items: center;
    background-image : url("../images/home_img2.jpg");

    .home_services{
        display: block;
    }
}


.home_heading{
    display: none;
    font-size: 70px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #ffff;
    padding: 10px;
}

.home_slogan{
    display:none;
    word-wrap: break-word;
    font-family: 'Caveat', cursive;
    color: #ffff;
    .slogan_part_2{
        font-family: 'Caveat', cursive;
        color: #000000;
    }
    font-size: 40px;
    padding: 10px;
    font-weight:700;
}
.home_services{
    display: none;
    word-wrap: break-word;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 50px;
    padding: 10px;
}

.home_contact_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    /* text-transform: uppercase; */
    position: relative;
    border-radius: 20px; 
    background-color: transparent;
    border-style: none;
    padding: 10px 20px;
    margin: 20px 10px 10px 10px ;
    
    background-color: black;
    color: white;
}
.home_contact_btn:hover{
    background-color:rgb(53, 52, 52);
    color:white;
    cursor: pointer;
    
}
.selector{
    display: flex;
    width: 40px;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    align-self: flex-end;
    margin-bottom: 10px;
}
.carousel{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(224, 222, 222);
    display: inline-block;
}
#zero{
    background-color: black;
}


