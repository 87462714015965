.contact_page{
    width: 100vw;
    height: fit-content;
    display: flex;
    justify-content: space-around;
    padding-top: 35px;
}

.info_section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width:50%;
    padding: 50px;

    .contact_heading{
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        font-size: 40px;
        padding-bottom: 25px; 
    }
    .contact_body{
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        font-size: 18px;
        padding-top: 15px;
        margin-right: 25px;
        white-space: pre-wrap;
    }
}

.form_container{
    display: flex;
    justify-content: flex-start;
    width:50%;
    padding: 50px;
    /* font-family: 'Poppins'; */
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    .contact_form{
        width:100%; 
        .input_container{
            padding: 0px 0px 20px 0px;
        }
    }
    .form_title{
        font-size: 19px;
        padding: 0px 0px 15px 0px;
        .title{
            font-family: 'Poppins';
            padding: 0px 0px 15px 0px;
        }
    }
   
    

    .content_required{
        font-size: 15px;
        color: grey;
        font-family: 'Poppins';
        padding: 0px 0px 15px 15px;
    }

    
    .email_container{
        padding: 0px 15px 15px 0px;  
    }

    .message_container{
        padding: 0px 15px 15px 0px; 
        .message{
            border: 1px solid #a9a9a9;
            width: 90%;
            padding:5px;
            size: 10px;
            margin-top: 10px;
            background-color: #f8f8f8;
            min-height: 100px;
            resize: vertical;
            cursor:auto;
        }
        .message:focus{
            outline-style: solid;
            outline-color: black;
            outline-width: 2px;
            outline-offset: 2px !important;
            font-size: 100%;
        }
    }

    
    
    
}
input{
    border: 1px solid #a9a9a9;
    width: 90%;
    height: 30px;
    padding:5px;
    font-size: 15px;
    margin-top: 10px;
    background-color: #f8f8f8;
}

.name-container{
    display: flex;
    padding: 0px 15px 15px 0px;
    width:90%;
    justify-content: space-between;
    .fname{
        width:45%;
    }
    .lname{
        width:45%;
    }
            
}

input:focus{
    outline-style: solid;
    outline-color: black;
    outline-width: 2px;
    outline-offset: 2px !important;
    font-size: 100%;
}

button{
    /* font-family: "Poppins"; */
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    background-color: black;
    color: white;
    font-size: large;
    border-radius:40px;
    border-style: none;
    padding: 10px 20px;
}
button:hover{
    cursor: pointer;
    background-color: rgb(53, 52, 52);
}

@media (max-width:625px) {
    .info_section{
        align-items: center;    }
    .contact_page{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .info_section{
        width:auto;
    }
    
    .form_container{
        width:auto;
    }
}