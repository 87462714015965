
.footer_wrapper{
    width: 100vw;
    display: flex;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    justify-content: space-between;
    background-color: rgb(228, 228, 228);
    .company_profile{
        display:flex;
        width:50%;
        padding:25px;
        .company_name{
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            font-size: 20px;
            /* width:50%; */
            padding-right:20px;
        }
        .info{
            /* width:50%; */
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 10px;
            .contact_info{
                margin-bottom: 25px;
                .email{
                    display: flex;
                    margin-bottom: 25px;
                    .email_logo{
                        height: 18px;
                        width:18px;
                    }
                }
                .contact_email:hover{
                    text-decoration: underline;
                    cursor: pointer;
                }
                .contact_phone{
                    display: flex;
                    .phone_logo{
                        height: 18px;
                        width:18px;
                    }
                }
                .phone{
                    width: fit-content;
                    /* text-decoration: none; */
                }
                .contact_phone:hover{
                    text-decoration: underline;
                    cursor: pointer;
                }
                
            }
            .address{
                display:flex;
                .add_logo{
                    height: 24px;
                    width:24px;
                }
                
            }
            .address:hover{
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
    
    
    .timing_info{
        width:30%;
        padding:20px;
        .contact_timings{
            
            margin-bottom: 20px;
            .heading{
                font-size: large;
                margin-bottom: 15px;
                font-weight: 700;
            }
            .weekday_time{
                margin-bottom: 10px;
            }
        }
        
    }

}

@media (max-width:650px) {
    .company_profile{
        flex-direction: column;
        width:50vw;
        /* align-items: center; */
        justify-content: space-evenly;
        .company_name{
            margin-bottom: 25px;
        }
        .contact_info{
            width: 90%;
        }
    }
    .timing_info{
        width: 40%;
        margin-top: 90px;
    }
    
}
 