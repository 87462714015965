.navbar{
    position:fixed;
    height: 50px;
    width: 100vw;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(0,0,0,0.25);
    backdrop-filter: blur(12px);
    color: white; 
}
.nav_options{
    font-size: small;
}
span{
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    padding: 25px;
}

span:hover{
    cursor: default;
}
.nav-op:hover{
    cursor: pointer;
}

a:link, a:visited {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.nav_logo{
    font-size:large;
}
.nav_logo:hover{
    cursor: pointer;
}
.dropdown_menu{
    display: none;
}
.hamburger{
    height: 25px;
    width: 25px;
    margin-right: 25px;
}

.hamburger:hover{
    cursor: pointer;
}
.dropdown_active{
    position: absolute;
    top: 50px;
    left:0px;
    width: 100vw;
    background-color: transparent;
    background-color: rgb(0,0,0,0.1);
    backdrop-filter: blur(12px);
    z-index: 2;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    justify-content:left;
    font-size: 15px;
}
.dropdown_inactive{
    display: none;
}
ul{
    list-style:none;
    align-items: left;
    padding: 0;
    margin: 0;
}
li{
    padding:5px 0px 5px 0px;
    color: black;
    >span{
        padding:0px 25px;
    }
}

li:hover{
    background-color: rgba(0,0,0,0.03);
    cursor: pointer;
}
@media (max-width:600px) {
    .nav_options{
        display: none;
    }
    .dropdown_menu{
        display: block;
    }
}