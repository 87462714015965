.about_section{
    width: 100vw;
    display: flex;
    
    padding-top: 30px;
    .about_img{
        width: 100vw;
        background-image: url("../images/about.png");
        background-size:cover;
        background-repeat: no-repeat;
        
    }
    .about_data{
        padding: 50px  25px;
        /* font-family: "Poppins";*/
        font-family:Verdana, Geneva, Tahoma, sans-serif; 
        width:100vw;
        background-color:rgb(228, 228, 228);
        line-height: 2em;;
        .title{
            font-size: 40px;
            padding-top: 50px;
            padding-bottom: 50px;
        }
        .body{
            font-size: 20px;
            padding-bottom: 25px;
        }
        .subbody{
            font-size: 15px;
            padding-bottom: 25px;
        }
        .conclude{
            font-size: 15px;
            padding-bottom: 25px;
        }
    }
   
    .contact_btn{
        display: flex;
        justify-content: center;
        align-items: center;
        /* font-family: "Roboto", sans-serif; */
        font-family:Verdana, Geneva, Tahoma, sans-serif;
        /* text-transform: uppercase; */
        position: relative;
        border-radius: 20px; 
        background-color: transparent;
        border-style: none;
        padding: 10px 20px;
        margin: 20px 10px 10px 10px ;
        
        background-color: black;
        color: white;
    }
    .contact_btn:hover{
        background-color:rgb(53, 52, 52);
        color:white;
        cursor: pointer;
        
    }
}

@media (max-width:1050px) {
    .about_img{
        display: none;
    }
    
}